<template>
  <div>
    <template
      v-for="(block, i) in getRichTextParts(value)"
    >
      <a
        v-if="block.type === 'url'"
        :key="i"
        :href="block.value"
        target="_blank"
      >
        {{ block.value }}
      </a>
      <span
        v-else
        :key="i"
      >{{ block.value }}</span>
    </template>
  </div>
</template>

<script>
const urlRegex = /(https?:\/\/[^\s]+)/gi;

export default {
  props: {
    value: String,
  },
  methods: {
    getRichTextParts(v) {
      const urls = (v || '').match(urlRegex) || [];

      return (v || '').split(urlRegex)
        .map((x) => ({
          type: urls.includes(x) ? 'url' : 'text',
          value: x,
        }));
    },
  },
};
</script>
