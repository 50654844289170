import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
  },
})
  .put({
    action: 'acceptUserRoleInvite',
    path: '/user/invite/accept',
  })
  .put({
    action: 'rejectUserRoleInvite',
    path: '/user/invite/reject',
  })
  .put({
    action: 'removeUserRole',
    path: '/user/invite/remove',
  })
  .getStore();

export default store;
