import axios from '@/api/axios';
import lang from '@/lang';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    properties: [],
  },
})
  .get({
    action: 'getPayersProperties',
    property: 'properties',
    path: '/payers-properties',
  })
  .get({
    action: 'getPayerPropertiesChangeLog',
    path: ({ payerId }) => `/payers/${payerId}/change-log`,
  })
  .post({
    action: 'addPayersProperty',
    path: '/payers-properties',
    onSuccess: (s, { data }) => {
      s.properties.push(data);
    },
  })
  .delete({
    action: 'removePayersProperty',
    path: ({ code }) => `/payers-properties/${code}`,
    onSuccess: (s, _, a, { params }) => {
      s.properties = s.properties.filter((x) => x.code !== params.code);
    },
  })
  .put({
    action: 'updatePayersProperty',
    path: '/payers-properties',
    onSuccess: (s, { data }) => {
      const index = s.properties.findIndex((x) => x.code === data.code);
      Vue.set(s.properties, index, data);
    },
  })
  .getStore();

store.getters = {
  properties: (s) => s.properties.slice().sort((a, b) => a.name.localeCompare(b.name)),
  payersPropertyTypes() {
    return [
      {
        key: 'Email',
        icon: 'far fa-envelope',
        text: lang.t('settings.clientField.email'),
      },
      {
        key: 'Phone',
        icon: 'far fa-phone',
        text: lang.t('settings.clientField.phone'),
      },
      {
        key: 'BankAccount',
        icon: 'far fa-dollar-sign',
        text: lang.t('settings.clientField.accountNumber'),
      },
      {
        key: 'Other',
        icon: 'far fa-font',
        text: lang.t('settings.clientField.other'),
      },
    ];
  },
};

export default store;
