import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    unread: 0,
    threads: [],
  },
})
  .get({
    action: 'getUserThreads',
    path: '/user/messages/threads',
  })
  .get({
    action: 'getUnreadThreadCount',
    path: '/user/messages/threads/unread',
    onSuccess: (s, { data }) => {
      s.unread = data.count;
    },
  })
  .post({
    action: 'createNewThread',
    path: '/user/messages/threads',
  })
  .get({
    action: 'getUserThreadMessages',
    path: ({ threadId }) => `/user/messages/threads/${threadId}`,
  })
  .post({
    action: 'replyToThread',
    path: ({ threadId }) => `/user/messages/threads/${threadId}`,
  })
  .put({
    action: 'markUserThreadAsRead',
    path: ({ threadId }) => `/user/messages/threads/${threadId}/read`,
  })
  .getStore();

store.getters = {
  userUnreadCount: (s) => s.unread,
};

export default store;
