import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    catalogs: null,
  },
})
  .get({
    action: 'getUserShop',
    path: '/user/shop/offer',
  })
  .post({
    action: 'createOrder',
    path: '/user/shop/order',
  })
  .post({
    action: 'getCreateOrderPreview',
    path: '/user/shop/order/preview',
  })
  .get({
    action: 'getOrdered',
    path: '/user/shop/orders',
  })
  .get({
    action: 'getOrdersSummary',
    path: '/user/shop/summary',
  })
  .get({
    action: 'getUserCatalogs',
    path: 'v2/user/shop/catalogs',
    property: 'catalogs',
  })
  .get({
    action: 'getCatalogDetails',
    path: 'v2/user/shop/offer',
  })
  .getStore();

store.getters = {
  userCatalogs: (s) => s.catalogs,
  catalogIcon: () => (productCategory) => {
    switch (productCategory.toLowerCase()) {
      case 'catering':
        return 'fa-fork-knife';

      case 'courses':
        return 'fa-baseball-bat-ball';

      case 'events':
        return 'fa-people-roof';

      case 'trips':
        return 'fa-campground';

      case 'items':
        return 'fa-tag';

      default:
        return 'fa-bag-shopping';
    }
  },
};

export default store;
