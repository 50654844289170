import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    imports: [],
  },
})
  .post({
    action: 'inviteUserToPayer',
    path: '/payers/user-invites',
  })
  .post({
    action: 'inviteUsersToPayers',
    path: '/payers/user-invites/many',
  })
  .put({
    action: 'withdrawUserInvite',
    path: '/payers/user-invites/withdraw',
  })
  .put({
    action: 'resendUserInvite',
    path: '/payers/user-invites/resend',
  })
  .put({
    action: 'removeUserPayerRole',
    path: '/payers/user-invites/remove',
  })
  .getStore();

export default store;
