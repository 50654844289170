import Vue from 'vue';
import Loader from '@/components/utils/Loader';
import CloseButton from '@/components/utils/CloseButton';
import PModal from '@/components/utils/PModal';

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

// This will allow to use this.$uId in components to get unique id for the instance
Vue.use({
  install() {
    Object.defineProperty(Vue.prototype, '$uId', {
      get: function $uId() {
        return this._uid; // eslint-disable-line no-underscore-dangle
      },
    });
  },
});

Vue.component('CloseButton', CloseButton);
Vue.component('PModal', PModal);
Vue.component('Loader', Loader);
