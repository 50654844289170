const getMetaData = (url) => ({
  issuer: url,
  authorization_endpoint: `${url}/connect/authorize`,
  userinfo_endpoint: `${url}/connect/userinfo`,
  end_session_endpoint: `${url}/connect/endsession`,
  token_endpoint: `${url}/connect/token`,
  jwks_uri: `${url}/.well-known/openid-configuration/jwks`,
});

export default {
  authority: process.env.VUE_APP_AUTH_URL,
  clientId: 'pagaspot.web',
  redirectUri: `${window.location.origin}/oidc-callback`,
  silentRedirectUri: `${window.location.origin}/silent-renew.html`,
  postLogoutRedirectUri: window.location.origin,
  responseType: 'code',
  scope: 'openid profile pagaspot.bills pagaspot.messages',
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  metadata: getMetaData(process.env.VUE_APP_AUTH_URL),
};
