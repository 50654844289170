import axios from '@/api/axios';
import lang from '@/lang';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    donations: null,
  },
})
  .get({
    action: 'getPayments',
    path: '/payments',
  })
  .get({
    action: 'exportPayments',
    path: '/payments/export',
    requestConfig: {
      responseType: 'blob',
    },
  })
  .get({
    action: 'getPayment',
    path: ({ paymentId }) => `/payments/${paymentId}`,
  })
  .post({
    action: 'addPayment',
    path: '/payments',
  })
  .put({
    action: 'settlePayment',
    path: ({ paymentId }) => `/payments/${paymentId}`,
  })
  .delete({
    action: 'deletePayment',
    path: ({ paymentId }) => `/payments/${paymentId}`,
  })
  .get({
    action: 'getPayerOverpayments',
    path: ({ payerId }) => `/payments/${payerId}/overpayments`,
  })
  .get({
    action: 'getPaymentsAudit',
    path: () => '/payments/audit',
  })
  .get({
    action: 'getPaymentAuditDetails',
    path: ({ operationId }) => `/payments/audit/${operationId}`,
  })
  .get({
    action: 'getDonations',
    property: 'donations',
    path: () => '/donations',
  })
  .getStore();

store.getters = {
  donations: (s) => s.donations,
  paymentTypes: () => [
    {
      key: 'Cash',
      icon: 'fas fa-money-from-bracket',
      name: lang.t('payment.method.cash'),
    },
    {
      key: 'Transfer',
      icon: 'fas fa-money-bill-transfer',
      name: lang.t('payment.method.transfer'),
    },
    {
      key: 'Card',
      icon: 'fas fa-credit-card-front',
      name: lang.t('payment.method.card'),
    },
    {
      key: 'Internal',
      icon: 'fas fa-mobile',
      name: lang.t('payment.method.internal'),
      system: true,
    },
  ],
};

export default store;
