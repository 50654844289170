<template>
  <BModal
    :visible="value"
    hide-footer
    hide-header
    size="lg"
    @change="$emit('input', $event)"
  >
    <slot />
  </BModal>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
};
</script>

<style>

</style>
