import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    list: [],
  },
})
  .get({
    action: 'getPaymentTransactions',
    property: 'payment',
    path: ({ paymentId, companyId, clientId }) =>
      `/company/${companyId}/payment/${paymentId}/client/${clientId}/transactions`,
  })
  .get({
    action: 'getBankStatementDetails',
    path: ({ companyId, bankTransactionId }) => `/company/${companyId}/bank/transaction/${bankTransactionId}`,
  })
  .post({
    action: 'addBankTransaction',
    path: ({ companyId }) => `/company/${companyId}/bank/transaction`,
  })
  .delete({
    action: 'ignoreBankTransaction',
    path: ({ companyId, bankTransactionId }) => `/company/${companyId}/bank/transaction/${bankTransactionId}/ignore`,
  })
  .get({
    action: 'getDeposits',
    path: ({ companyId, skip, take }) => `/company/${companyId}/deposits/${skip}/${take}`,
  })
  .get({
    action: 'getDeposit',
    path: ({ companyId, depositId }) => `/company/${companyId}/deposit/${depositId}`,
  })
  .post({
    action: 'addDeposit',
    path: ({ companyId }) => `/company/${companyId}/deposit`,
  })
  .put({
    action: 'updateDeposit',
    path: ({ companyId, depositId }) => `/company/${companyId}/deposit/${depositId}`,
  })
  .delete({
    action: 'removeDeposit',
    path: ({ companyId, depositId }) => `/company/${companyId}/deposit/${depositId}`,
  })
  .getStore();

export default store;
