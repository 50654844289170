import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const user = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getUserDetails',
    path: 'user/details',
  })
  .get({
    action: 'getUserProperties',
    path: 'user/properties',
  })
  .put({
    action: 'setUserPayerProperties',
    path: 'user/properties',
  })
  .put({
    action: 'setUserPhoneNumber',
    path: 'user/phone',
  })
  .getStore();

export default user;
