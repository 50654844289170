import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import { money } from '@/utils/filters';

const store = new Vapi({
  axios,
  state: {
    currencies: [],
  },
})
  .get({
    action: 'getCurrencies',
    path: () => 'currencies',
    onSuccess: (s, { data }) => {
      s.currencies = data;
    },
  })
  .getStore();

store.mutations.setCurrencies = (s, currencies) => {
  s.currencies = currencies;
};

store.getters = {
  formatCurrency: (s) => (value, key, skipCurrencySign) => {
    const currencySpec = s.currencies.find((e) => e.code.toLowerCase() === (key || '').toLowerCase());
    const formatted = money(value, currencySpec?.decimals || 0);
    if (!currencySpec || skipCurrencySign) return formatted;

    return currencySpec.format.replace('%', formatted);
  },
  formatMoney: (s) => (value, key, skipCurrencySign) => {
    const currencySpec = s.currencies.find((e) => e.code.toLowerCase() === (key || '').toLowerCase());
    const formatted = money(value, currencySpec?.decimals || 0);
    if (!currencySpec || skipCurrencySign) return formatted;

    return `${formatted} ${key}`;
  },
  currencies: (s) => s.currencies,
};

export default store;
