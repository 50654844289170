import Vue from 'vue';
import VueI18n from 'vue-i18n';

// translations
const pl = require('./languages/pl');
const en = require('./languages/en');

// load necessary moment locales
require('moment/locale/pl');
require('moment/locale/en-gb');

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  messages: {
    pl,
    en,
  },
});

export default i18n;
