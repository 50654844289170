import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    selected: [],
  },
})
  .get({
    action: 'getPayers',
    path: () => '/payers',
  })
  .post({
    action: 'exportPayers',
    path: () => '/payers/export',
    requestConfig: {
      responseType: 'blob',
    },
  })
  .get({
    action: 'getPayersByIds',
    path: () => '/payers/ids',
  })
  .get({
    action: 'getArchivedPayers',
    path: () => '/payers/archived',
  })
  .put({
    action: 'updateGroupAssignment',
    path: '/payers/group-assignment',
  })
  .get({
    action: 'getPayerDetails',
    path: ({ payerId }) => `/payers/${payerId}`,
  })
  .post({
    action: 'addPayer',
    path: () => '/payers',
  })
  .post({
    action: 'addPayersBatch',
    path: () => '/payers/batch',
  })
  .put({
    action: 'updatePayer',
    path: ({ payerId }) => `/payers/${payerId}`,
  })
  .put({
    action: 'archivePayers',
    path: '/payers/archive',
  })
  .put({
    action: 'restorePayers',
    path: '/payers/restore',
  })
  .getStore();

store.mutations.setSelectedPayers = (s, payers) => {
  s.selected = payers;
};

store.getters = {
  selectedPayers: (s) => s.selected,
};

export default store;
