import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .post({
    action: 'addBillsCategory',
    path: () => '/bills-setup/categories',
  })
  .put({
    action: 'updateBillsCategory',
    path: ({ categoryId }) => `/bills-setup/categories/${categoryId}`,
  })
  .put({
    action: 'recoverBillsCategory',
    path: ({ categoryId }) => `/bills-setup/categories/${categoryId}/recover`,
  })
  .delete({
    action: 'deleteBillsCategory',
    path: ({ categoryId }) => `/bills-setup/categories/${categoryId}`,
  })
  .getStore();

export default store;
