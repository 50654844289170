import vars from '../styles/export.icss.scss';

// Use colors from scss
const colorsDef = vars;

const getColor = (tag) => {
  const color = Object.keys(colorsDef).find((el) => el === tag);
  if (color) {
    return colorsDef[tag];
  }
  return '#28A745';
};

export default getColor;
