import axios from 'axios';
import Qs from 'qs';

export default axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || 'http://localhost:8000',
  paramsSerializer(params) {
    const { query } = params;
    if (query) {
      return Qs.stringify(query, { arrayFormat: 'repeat' });
    }
    return null;
  },
});
