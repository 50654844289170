import axios from '@/api/axios';
import lang from '@/lang';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    permissions: [],
  },
})
  .get({
    action: 'getPayeeAccess',
    path: 'payee/access',
    headers: ({ tenantId }) => ({
      tenantId,
    }),
  })
  .get({
    action: 'getPayeeAdmins',
    path: 'payee/access/admins',
  })
  .post({
    action: 'addPayeeRole',
    path: 'tenants/roles',
    headers: ({ tenantId }) => ({
      tenantId,
    }),
  })
  .put({
    action: 'removePayeeRole',
    path: 'tenants/roles/remove',
    headers: ({ tenantId }) => ({
      tenantId,
    }),
  })
  .get({
    action: 'getUserPermissions',
    path: 'user/access',
    property: 'permissions',
  })
  .getStore();

store.getters = {
  permissions: (s) => s.permissions,
  systemAdmin: (s) => s.permissions.some((x) => x.type === 'SystemAdmin'),
  tenantAdmin: (s, g, rs, rg) => g.systemAdmin
  || s.permissions.some((x) => x.type === 'TenantAdmin'
        && x.tenantId === rg.payee.ownerId),
  roleTypes() {
    return [
      {
        key: 'PayeeAdmin',
        icon: 'fas fa-user-tie',
        text: lang.t('settings.roles.types.payeeAdmin'),
      },
      {
        key: 'PayersView',
        icon: 'fas fa-users',
        text: lang.t('settings.roles.types.payersView'),
      },
      {
        key: 'ShopOrdersView',
        icon: 'fas fa-store',
        text: lang.t('settings.roles.types.shopOrdersView'),
      },
    ];
  },
};

export default store;
