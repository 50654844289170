<template>
  <button
    type="button"
    data-dismiss="modal"
    aria-label="Close"
    class="btn btn-light icon-btn"
    data-test="close-button"
  >
    <i class="fas fa-times" />
  </button>
</template>

<script>
export default {

};
</script>

<style>

</style>
