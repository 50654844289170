import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    companies: {},
    currencyDictionary: [],
  },
})
  .post({
    action: 'paymentInit',
    path: '/user/pay',
  })
  .post({
    action: 'notifyPaymentStart',
    path: ({ id, date, hash }) => `/user/pay/notification/${id}/${date}/${hash}`,
  })
  .get({
    action: 'getInternalPaymentInfo',
    path: ({ orderId }) => `/user/pay/info/${orderId}`,
  })
  .getStore();

export default store;
