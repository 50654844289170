<template>
  <div>
    <Loader />
    {{ error }}
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'OidcCallback',
  data: () => ({
    error: null,
    pending: false,
  }),
  methods: {
    ...mapActions('oidc', [
      'oidcSignInCallback',
    ]),
  },
  mounted() {
    this.pending = true;
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath);
      })
      .catch((err) => {
        this.pending = false;
        this.$router.replace('/');

        // eslint-disable-next-line
        console.error(err);
        this.error = err;
      });
  },
};
</script>
