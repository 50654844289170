import i18n from '@/lang';
import moment from 'moment-timezone';

const state = {
  browserLang: navigator.language || navigator.userLanguage || 'pl-PL',
  language: null,
  timezone: moment.tz.guess(),
  showOverlay: false,
};

const mutations = {
  toggleOverlay(s, v) {
    s.showOverlay = v;
  },
  setLanguage(s, lang) {
    s.language = lang;
    i18n.locale = lang;
    if (lang) {
      localStorage.setItem('language', lang);
      moment.locale(lang, {
        week: {
          dow: 1,
        },
      });
    }
  },
};
const actions = {
  readFromStorage(ctx) {
    // Get language
    const lang = localStorage.getItem('language');
    if (lang) {
      ctx.commit('setLanguage', lang);
    } else {
      const browserLang = ctx.state.browserLang ? ctx.state.browserLang.substring(0, 2) : 'pl';
      ctx.commit('setLanguage', browserLang);
    }
  },
  clear(ctx) {
    ctx.commit('clearGroups');
  },
};
const getters = {
  showOverlay: (s) => s.showOverlay,
  locale: (_, g) => (g.language ? g.language.lang : 'en'),
  isAdmin: (s, g, rs, rg) => (rg['oidc/oidcUser'] || {}).sysAdmin,
  language: (s, g) => {
    const locale = s.language || (s.browserLang ? s.browserLang.substring(0, 2) : null);
    return g.languages.find((l) => l.lang === locale) || g.languages[0];
  },
  languages: () => [
    {
      name: 'English',
      lang: 'en',
      flag: 'gb',
    },
    {
      name: 'Polski',
      lang: 'pl',
      flag: 'pl',
    },
  ],
};
export default {
  state,
  mutations,
  actions,
  getters,
};
