import axios from './axios';
import store from '../store';

export default () => {
  /*
    Adds token to 'Authorization' and 'Accept-language' header for authentication
  */
  axios.interceptors.request.use((config) => {
    const { lang } = store.getters.language;
    const token = store.getters['oidc/oidcAccessToken'];
    // clone the config
    const conf = { ...config };

    if (token != null) {
      conf.headers.Authorization = `Bearer ${token}`;
    }

    if (lang) {
      conf.headers.Language = lang;
    }

    return conf;
  }, (err) => Promise.reject(err));
};
