/* eslint-disable max-len */

module.exports = {
  organization: 'Organisation',
  person: 'Person',
  login: {
    login: 'Log In',
    register: 'Register',
    validated: 'Verified',
    forgetPass: 'I forgot my password',
    emailAdress: 'Email address',
    password: 'Password',
    confirmPassword: 'Confirm password',
    accept: 'Accept',
    termsAndConditions: 'Terms and conditions',
    samePasswordsError: 'Passwords must be identical!',
    confirmViaEmail: 'Check your inbox to verify your account',
    confirmViaEmailCloseTab: 'Check your inbox to verify your account \n Do not close this tab',
    checkSpam: 'Check your spam folder',
    enterEmail: 'Enter your email',
    invalidEmail: 'Email address invalid',
    validating: 'Validating...',
    closeThisWindow: 'You can now close this tab',
    invitationExpired: 'The invitation has expired',
    phoneNumber: 'Phone number',
    forSmsNotifications: 'For SMS notifications (optional)',
  },
  paymentStatus: {
    copyLink: 'Copy link',
    unableToGetData: `Unable to retrieve the data. The address is incorrect or has expired. Log in to check your balance'
      `,
    payAll: 'Pay all',
    report: 'Report error',
    reportText: `The following balance may differ from the one from the notification, if the organisation made a change or registered a new payment. 
    Pagaspot registers transactions and cash payments with a time delay, what also causes discrepancies in the account balance.
   If you see a mistake, report it to the organisation`,
    goBack: 'Go back',
    reportTitle: 'Report incorrect billing',
    selectAccountError: 'It is not possible to make a payment from different bank account',
    messageSent: `<b>The message has been sent</b><br> Log in to see the selected payments. 
      The organisation will make changes or reach out to you to explain the billings.`,
  },
  terms: {
    title: 'Terms and Conditions',
    comingSoon: 'Coming soon',
  },
  general: {
    name: 'Name',
    code: 'Code',
    title: 'Title',
    send: 'Sent',
    save: 'Save',
    saved: 'Saved!',
    add: 'Add',
    edit: 'Edit',
    manage: 'Manage',
    verify: 'Verify',
    sent: 'Sent',
    cancel: 'Cancel',
    remove: 'Delete',
    confirmRemove: 'Confirm delete',
    search: 'Search',
    close: 'Close',
    open: 'Open',
    value: 'Value',
    from: 'From',
    to: 'To',
    export: 'Export',
    select: 'Select',
    next: 'Next',
    back: 'Back',
    undo: 'Undo',
    description: 'Description',
    change: 'Change',
    confirm: 'Confirm',
    signature: 'Seal and signature',
    sortBy: 'Sort by',
    groupBy: 'Group by',
    default: 'Default',
    selectAll: 'Select all',
    comingSoon: 'Available soon',
    skip: 'Skip',
    archive: 'Archive',
    restore: 'Restore',
    dataFreshness: 'Data freshness',
    yes: 'Yes',
    no: 'No',
  },
  menu: {
    clientView: 'Client view',
    home: 'Home',
    me: 'Me',
    payers: 'Payers',
    bills: 'Bills',
    billingPlans: 'Billing plans',
    payments: 'Payments',
    reports: 'Reports',
    messages: 'Messages',
    shop: 'Shop',
    settings: 'Settings',
    logout: 'Log out',
    enrollement: 'Enrollement',
    accounting: 'Accounting documents',
    companies: 'Companies',
    userSettings: 'Settings',
    import: 'Import',
    notifications: 'Notifications',
    changePassword: 'Change password',
    version: 'Versions',
    tooltip: {
      liabilities: `Hover your mouse over the receivables column in order to see 
      the outstanding amount, pending payments and overpayments.<br>
      <i class="fas fa-exclamation-circle text-danger" ></i> - the payer has not settled his bill on time <br>
      <i class="far fa-clock text-secondary" ></i> -
       the payer has a payment due which has not expired yet<br>
      <i class="fas fa-hand-holding-usd text-success" ></i> -the payer has an overpayment`,
      addClient: `Use this function by adding a new payer. 
      Remember, you can add multiple payers from an excel spreadsheet bo going to Settings -> Payers -> Import new payers `,
      checkbox: `Choose payers in order to: <br>
      - send a new message via Pagaspot <br>
      - add a new bill <br>
      - add to a group<br>
      - delete<br>
      - sent an activation - invitation to create a Pagaspot account`,
      clientActivation: `<i class="fas fa-check-circle text-success" > </i> -
      The payer has registered and can access their billings <br>
      <i class="fas fa-paper-plane text-primary" > </i>  - The payer received an invitation to establish an account`,
    },
  },
  shop: {
    products: 'Products',
    payers: 'Payers',
    productSets: 'Sets',
    productSet: 'Set',
    noOrdersForToday: 'There are no sets ordered for today',
    noteToOrder: 'Note to the order',
    showProducts: 'Show products ',
    hideProducts: 'Hide products',
    addAvailability: 'Plan availability',
    addMissingProducts: 'Automatically add missing products',
    plan: 'Schedule',
    price: 'Price',
    screenForDelivery: 'Delivery screen',
    showUnpaid: 'Show unpaid',
    available: 'Available orders',
    ordered: 'Ordered',
    unpaid: 'Unpaid',
    calendar: 'Calendar',
    list: 'List',
    orderSuccessful: 'Order successful!',
    availabilityRule: 'Restrict availability',
    comment: 'Comment',
    delivery: {
      first: 'First serving',
      next: '{0} serving!',
    },
    reports: {
      send: 'Send reports',
      sendReport: 'Send a new report',
      paid: 'Paid',
      unpaid: 'Unpaid',
      selectReportType: 'Select report type',
      filterGroups: 'Filter groups',
      includeProperties: 'Include',
      emails: 'Email',
      lineSeparated: 'In new lines',
      emailsInLines: 'email 1\nemail 2\n...',
      hour: 'Time',
      sort: 'Order by:',
      includedProperties: 'Included fields (order corresponds to sort)',
      availableProperties: 'Available fileds',
      name: 'Report name (visible in email title)',
      type: {
        dailyOrders: 'Detailed report with orders for each payer',
        dailyProductSetSummary: 'Summary report with the number of ordered sets, for selected payers groups',
      },
    },
    form: {
      selectPayer: 'Select payer',
      selectDays: 'Select day',
      selectProductSet: 'Select set',
    },
    settings: {
      module: 'Store module',
      enabledInfo: 'Enable store module',
      orderRules: 'Order rules',
      allowMultipleSets: 'Allow ordering multiple identical sets',
      allowMultipleSetsInfo: 'Allow ordering identical set several times a day',
      productSetTypes: 'Set types',
      productSetType: 'Set type',
      addType: 'Add new set type',
      editType: 'Edit set type',
      typeName: 'New set name',
    },
    downloadReport: 'Download a report',
    confirmOrder: 'Confirm',
    toBeCanceled: 'Cancel',
    orderCreated: 'Your order has been accepted',
    productSetRemoveWarning: `
      This set's entire availability calendar will be deleted! 
      Past orders of this set can still be visible`,
    import: 'Import products and schedule',
    confirmImport: 'Confirm import',
    unableToCreateOrder: 'An error  occurred, the order has not been created. Please contact us: pomoc@pagaspot.com',
    thisIsShop: `
      Here is where products and services offered by the organisations will appear,
      such as lunches, camp registration forms, club jackets, etc.<br><br>
      If you cannot find, for instance, a lunch offer here, it means that the organisation hasn't enabled the store function`,
    thisWillBeAShop: `Here you can share with the payers your products and services,
    such as lunches, camp registration forms, club jackets, etc.<br><br>
      For now we only enabled this module for organisations participating in trials, 
      if you would like to join them, contact us at <b>pomoc@pagaspot.com</b>`,
    mealOffer: 'Lunch offer',
    shopPayerHelp: `
      To order your meals, choose days from the calendar.<br>
      <b>To select multiple days, move your cursor/finger across them.  </b><br><br>
      Select each day to see details of the meals.<br><br>
      You can add and delete your sets as you wish. You will confirm your order during checkout.`,
    availability: 'Availability',
    addPayersGroup: 'Add new payers group',
    addProduct: 'Add product',
    editAvailability: 'Change availability',
    addProductSet: 'Add new set',
    newProduct: 'Add new product',
    addOrders: 'Add new order',
    newProductSet: 'New order',
    orderedSets: 'Sets ordered',
    orderSet: 'Order a set',
    selectDays: 'Choose a day to see available sets',
    orderTime: 'Order time',
    orderTo: 'Order to',
    orderToInfo: 'Until when (or what time) are the products available to order',
    orderFrom: 'Order from',
    orderFromInfo: 'Until when are the products available to order',
    timeBoundaries: 'Time limits',
    orderFulfillmentBased: 'Todays order',
    cancelTo: 'Cancel until',
    cancellationExpired: 'Cancellation deadline has expired',
    cancelToInfo: 'Until when (or what time) can the user cancel a planned order',
    payTo: 'Pay until',
    payToInfo: 'The latest the order must be paid',
    tagsAlergens: 'Tags (np. allergens)',
    tags: 'Tags',
    notPaid: 'Unpaid',
    notPaidPlural: 'Unpaid',
    offerNotAvailable: 'The offer is unavailable',
    paid: 'The order has be been paid',
    paidPlural: 'The orders has be been paid',
    tag: 'Tag',
    orders: 'Orders',
    cancellations: 'Cancellations',
    cancel: 'Cancel',
    order: 'Order',
    orderMore: 'Order more',
    orderAndPay: 'Order and pay',
    error: {
      ProductDupliated: 'There already exists  \'{0}\'. The name cannot repeat itself',
      InvalidTimeBoundary: 'Invalid time boundary \'{0}\'',
      NoPriceForProductSet: 'There is no price set for \'{0}\'',
      UnknownProductSet: 'Order  \'{0}\'not recognised, before you import an order schedule, you must create the orders first',
      UnrecognizedProduct: 'Product  \'{0}\' not recognised, add it manually or import a file',
      ProductNotUsed: 'Product  \'{0}\' will be added, even though does not appear in the schedule',
      SetsInScheduleMissingInAvailabilities: 'Product sets  \'{0}\' appear in schedule, but not in set tab',
      SetsInAvailabilitiesMissingInSchedule: 'Product sets  \'{0}\' appear in product set tab, but not in schedule tab',
      IncompleteAvailabilityDefinition: `
        Sets scheduled do not match with those in the "Sets" section. 
        Please make sure that the names of sets in the 'Schedule' and 'Sets' sections are the same.`,
      UnableToSave: 'Unable to save',
      InvalidGroupName: 'Unrecognized payer group: \'{0}\'',
    },
  },
  report: {
    report: 'Report',
    balanceReport: 'Balance report',
    settlementReport: 'Settlement report',
    customRange: 'Choose range',
    billsFromTheScope: 'Bills from the selected period',
    paidFromTheScope: 'Payments made in the selected period',
    notPaidFromTheScope: 'Unpaid bills from the selected period',
    paymentsFromTheScope: 'Payments from the selected period',
    time: {
      currentMonth: 'Current month',
      previousMonth: 'Previous month',
      lastThreeMonths: 'Previous 3 month',
      thisYear: 'This year',
    },
    balance: {
      balanceBefore: 'Balance before',
      balanceAfter: 'Balance after',
      due: 'Amount due',
      paid: 'Payment',
      refund: 'Refund',
      settled: 'Settled',
    },
  },
  userSettings: {
    newPassword: 'New password',
    changePassword: 'Change password',
    currentPassword: 'Current password',
    passwordsMustBeIdentical: 'Passwords must be identical',
    tooShortPassword: 'Password is too short (at least 5 characters)',
  },
  groups: {
    groups: 'Groups',
    group: 'Group',
    newGroup: 'New group',
    edit: 'Edit group ',
    add: 'Add new group',
    select: 'Choose group',
    everyoneInGroup: 'Everyone in the group',
    selectAll: 'Select all',
  },
  payers: {
    add: 'Add new payer',
    archivedPayers: 'Archived payers',
    archived: 'Archived',
    seeBefore: 'See archived payers before {0}',
    noArchived: 'No archived payers',
    balance: 'Balance',
    activation: 'Activation',
    invite: 'Invite',
    noEmails: 'No saved email addresses',
  },
  company: {
    theCompany: 'Company',
    companyCreated: 'Company was created',
    clientFields: 'Additional fields',
    companies: 'Companies',
    all: 'Everyone',
    noPayers: 'No payers',
    selectPayers: 'Select payers',
    allClientsSelected: 'All selected payers',
    newClient: 'New client',
    connectedUsers: 'Connected users',
    companyInfo: 'Company information',
    contactInfo: 'Contact information',
    giveOtherInvoiceInfo: 'Provide more details for the invoice',
    otherInvoiceInfo: 'Other company details for the invoice ',
    nameTaken: 'The name "{name}" is taken. Please choose another name',
    newCompanyRegister: 'New company registration form',
    addNewCompany: 'Add new company',
    accountType: {
      accountType: 'Account type',
      license: 'Licence',
      demo: 'DEMO',
    },
    useDemoAccount: 'Please note, you are currently using the DEMO account',
    activateAccount: '(Click to activate)',
    logoutToAccess: 'You must log in again to access the new company',
    stayLoggedIn: 'Stay logged in',
    logOutNow: 'Log out',
    activateCompany: 'Activate company',
    chooseAccountType: 'Choose account type to activate company',
    accountTypes: {
      fullLicense: 'Full licence ',
      nonProfit: 'Non-profit',
      enterprise: 'Enterprise',
    },
    sendForVerification: 'Send for verification',
    isBeingVerified: 'Your account is being verified, you can use the DEMO account',
    confirmActivate: 'Confirm activation request',
    confirmToUnlock: `You are currently using the DEMO account. 
      Confirm activation request to gain access to all the account features`,
  },
  demoAccount: {
    notAvailable: 'Unavailable in the DEMO version',
    clientsLimit: 'User limit in the DEMO version: 500',
  },
  form: {
    clearAll: 'Clear all',
    selected: 'Selected',
    overwriteAmount: 'Select amount {0} {1} for everyone',
    selectPayers: 'Select payers',
    selectAmount: 'Select amount',
    addPayers: 'Add new payers',
    titleAndDescription: 'Title and description',
    summary: 'Summary',
    weSavedYourWork: 'Work saved',
    restore: 'Restore',
    decisionMaker: 'I am the decisionmaker',
    acceptTerms: `I accept the terms and conditions. 
    The administrator of your personal data is Pagaspot Sp. z o.o., 
      NIP: 5223176796, ul. Wyrzyska 16 A, 02-455 Warszawa.
      Your data will be processed for maintaining your Pagaspot.`,
    iAccept: 'Accept',
    termsCommercialUsers: 'Terms for commercial users',
    mandatoryAgreements: 'These agreements are mandatory',
    defaultAccountType: `DEMO account is the default account type. 
    You can begin the verification process now or later in settings.`,
    street: 'Street',
    postCode: 'Postcode',
    city: 'City',
  },
  client: {
    me: 'Me',
    active: 'Active',
    basket: 'Basket',
    position: 'Position',
    number: 'Payer number',
    pendingInvitation: 'Pending invitation',
    pendingInvitationInfo: 'After you accept the invitation you will be able to see your balance, make payments as well as make an order',
    clientCode: 'Client code',
    noConnectedClients: 'No connected clients found!',
    noConnectedClientsInfo: `
      Make sure you have logged in with the same email which was provided in the registratin form or provided to the organisation.<br>
      If you still cannot see your bills, contact the organisation which sent you the invitation<br>
      <br>
      If you would like to manage bills for your own organisation through Pagaspot, contact us at pomoc@pagaspot.com`,
    noConnectedClientsShopInfo: 'Go back to Dashboard',
    activation: 'Activate',
    excessFor: 'Excess payment for',
    receiver: 'Receiver',
    bills: 'To pay',
    paid: 'Already paid',
    paymentSettledInBackground: 'The payment will be settled in the background',
    overLimit: 'The amount of a single payment cannot exceed <b>{n} zł</b>',
    internalPaymentInfo: 'We will add a fee of<b>{n} zł</b> for processing the transaction.',
    seePaid: 'See paid',
    seeUnpaid: 'See unpaid',
    groupsInfo: 'Selected payments are to made to separate accounts. Please click "Pay" for each group separately or make a transfer using the details below.',
    gatewayDisabled: 'The payee disabled the gateway. Please make a bank transfer.',
    pay: 'Pay',
    creditReturn: 'Refund from',
    paymentRegistered: 'The payment has been registered',
    disconnectAccount: 'Disconnected the payer\'s account',
    import: 'Import new payers',
    pendingInvitations: 'Pending invitations',
    late: 'Overdue',
    notLate: 'Not overdue',
    clickToPay: 'Click here to pay',
    clickToConfirm: 'Click here to confirm',
    alreadySettled: 'Already settled <b>{settled}</b> from overall <b>{value}</b> of this payment',
    payment: {
      toPay: 'To pay',
      paid: 'Paid',
    },
    settings: {
      settings: 'Settings',
      notifications: 'Notifications',
      connectedClients: 'Connected clients',
      notifyNewPayments: 'Notify me about new due payments and changes',
      remindersInfo: `The notifications me send about due payments come from the organisation.   
      If you wish not to receive them, contact the organisation which sends them.`,
    },
    name: 'Name and surname',
    firstName: 'Name',
    lastName: 'Surname',
    email: 'Email',
    activate: 'Activate',
    inviteSent: 'Invitation has been sent',
    activated: 'Activated',
    notInvited: 'Not invited',
    sendAgain: 'Send again',
    sendAgainIfNoResponse: 'Send again to not activated payers',
  },
  activation: {
    activation: 'Activation of payers\' account. ',
    clientActivation: 'Account activation {0}',
    invitationAlreadySent: 'The invitation has already been sent',
    willBeActivated: 'Activated payers will receive invitation via the email addresses provided.',
    noEmail: 'These payers will not be activated because they do not have an email address. Provide it before activation.',
  },
  transactions: 'Transaction',
  transaction: {
    ignore: 'Remove',
    removeAndIgnore: 'Remove and ignore',
    settle: 'Settle',
    transferSettlementInvalid: 'The transfer was settled incorrectly',
    addTransaction: 'Add new payment',
    amount: 'Payment amount',
    no: 'No transaction',
    value: 'Transation value',
    add: 'Add new payment',
    import: 'Import a statement',
    remove: 'Remove',
    selectClient: 'Choose client',
    provideTransactionValue: 'Transaction amount',
    fromExcessPayment: 'From excess payments',
  },
  bankStatements: {
    title: 'Statements',
    bankStatement: 'Statement',
    transferStatus: 'Transfer status',
    suggestSettlement: 'Suggest settlement',
    noStatements: 'No statements found in {0}',
    transferDetails: 'Transfer details',
    duplicateWarning: 'This transfer is a duplicate of a transfer from another statement. Change the statement to change its settlement.',
    transfer: {
      skip: 'Skip in settlements',
      settle: 'Settle',
      startSettle: 'Begin settling',
    },
    statuses: {
      unsettled: 'Not settled',
      settled: 'Settled',
      skipped: 'Skipped',
      single: {
        settled: 'Settled',
        skipped: 'Skipped',
      },
    },
  },
  billingScopes: {
    billingScopes: 'Billing periods',
  },
  bill: {
    settled: 'Settled',
    none: 'No receivables to show',
    noneToPay: 'No payments due',
    edit: 'Edit',
    add: 'Add new receivable',
    import: 'Import receivables and payments',
    showFuture: 'Show future billing periods',
    toPay: 'To pay',
    editDetails: 'Edit details',
    category: 'Receivables',
    amount: 'Amount due ',
    editPayers: 'Edit payers',
    allPaymentsMovedToExcess: 'All payments for this billing will be moved to excess payment and automatically cover outstanding balance',
    closeScopeInfo: 'New payments and bill modifications are unavailable after the end of the billing period',
    closeScope: 'Close billing period',
    openScopeInfo: 'Adding new payments and changing billings will be possible after opening of the new billing period',
    openScope: 'Open billing period',
    plans: 'Plans',
    changeHistory: 'Change history',
    notAssigned: 'Payer not assigneed to this billing',
    resettlementNeeded: 'Payments of {0} exceed the amount due',
    resettleForCategory: 'Change category for these payments',
    audit: {
      BillPayerAddedEvent: 'Payer added',
      BillPayerRemovedEvent: 'Payer removed',
      BillPayerUpdatedEvent: 'Billing changed',
      MoneyAddedToBillEvent: 'Payment settled ',
      MoneyRemovedFromBillEvent: 'Payment removed',
    },
    error: {
      UnknownPayer: 'Unknown payer \'{0}\'',
      DuplicatedPayer: 'Payer \'{0}\' is duplicated in the file',
      MultipleMatchingPayers: 'Multiple matches for payer \'{0}\' ',
    },
  },
  billingPlan: {
    add: 'Add billing plan',
    to: 'Until',
    select: {
      all: 'All',
      enabled: 'Active',
      disabled: 'Disabled',
    },
    generateDaysBefore: 'Generate billing before the deadline (days)',
    none: 'No defined billing plans',
    activate: 'Activate plan',
    enabled: 'Active',
    disabled: 'Disabled',
  },
  payment: {
    none: 'No payments',
    due: 'Amount due',
    add: 'Add payment',
    overpayment: 'Overpayment',
    toSettle: 'To settle',
    details: 'Details',
    print: 'Print',
    editSettlement: 'Edit settlement',
    settlement: 'Settlement',
    confirmation: 'Payment confirmation',
    for: 'Payment for',
    subject: 'Subject',
    date: 'Date',
    number: 'Number',
    settled: 'Settled',
    unsettled: 'Not settled',
    otherAmount: 'other',
    addUnsettled: 'Add new unsettled payment',
    payment: 'Payment',
    donation: 'Donation',
    creditReturn: 'Refund',
    amountAndMethod: 'Amount and payment method',
    show: 'Show amount',
    settledFor: 'Settled for',
    title: 'Payment title',
    description: 'Description',
    amount: 'Amount',
    remaining: 'Amount remaining',
    recurring: 'Recurring payment',
    single: 'Single payment',
    generate: {
      generate: 'Generate',
      lastGen: 'Last generated',
      selectDates: 'Select dates',
      willBeGenerated: 'WIll be generated',
    },
    autoPayment: 'Automatic payment',
    selectPayment: 'Select payment',
    editPayment: 'Edit payment',
    repeatEvery: 'Repeat every',
    from: 'Display from',
    to: 'Payment date',
    startDate: 'Billing start date',
    repeatFrom: 'Start from',
    repeatTo: 'Stop billing',
    daysToPay: 'Days to pay',
    templates: 'Templates',
    addTemplate: 'Add template',
    sum: 'Sum',
    paid: 'Paid',
    paidOnTime: 'Paid on time',
    paidLate: 'Paid after deadline',
    notPaid: 'Not paid',
    warningNoBankAccount: 'Account "{0}" for this payer is invalid',
    toPay: 'To pay',
    settleForUnPaid: 'Settle <b>{0}</b> for unpaid',
    noSettleWarning: 'Billed amount will not be settled!',
    addClient: 'Add payers',
    addDeleteClients: 'Add / Delete payers',
    addClients: 'Add clients to bill',
    addToTemplate: 'Add clients to template',
    addParam: 'Add field',
    waiting: 'Pending',
    additional: 'Additional information',
    paymentHistory: 'Payment history',
    clientDeclaredPayment: 'Payers declared payment',
    editRecurring: 'Edit recurring payment templates',
    noRecurringPayments: 'No recurring payment templates defined',
    isAccountNumber: 'Is this the account number?',
    accountNumber: 'Account number',
    toStart: 'From beginning',
    toEnd: 'To end',
    toDeadline: 'To deadline',
    afterDeadline: 'After deadline',
    addPayment: 'Add payment',
    savePayment: 'Save payment',
    notify: 'Notify about new billing',
    notification: 'Notification',
    individualAmount: 'Individual amounts',
    completeInfo: `
      Billing will be terminated.\n
      Payers will not be able to see it, regardless if they made the payment or not.`,
    completedInfo: `<b>Payment received from all the payers!</b><br> 
      Select payment as complete, you will be able to see it in "Billing history"`,
    paymentMethod: 'Payment method',
    method: {
      transfer: 'Transfer',
      card: 'Card',
      cash: 'Cash',
      internal: 'App',
    },
    tooltip: `Select a biling and you will see its details.<br>
    By selecting:<br>
    Edit billing - name, description, select new receivables channel, and choose if a payer will see the receivable and its deadline<br>
    Export -  download information about your billins to Excel<br>
    Add payer - find a payer, choose amount and add them to the billing<br>
    Change billing - change amount or delete payer from the billing`,
    tooltipTemplate: `In templates you can add a recurring payment and edit existing ones. By changing/deleting payers and
     editing the billing amout you will add a new biling with the updated information. `,
  },
  forms: {
    open: 'Open',
    inactive: 'Form inactive',
    separator: 'Separator',
    clone: 'Copy',
    action: {
      payerAdd: 'Add payer',
    },
    forms: {
      layout: 'Layout',
      mapping: 'Mapping',
      validationScript: 'Validation script',
      validationMessage: 'Message about validation error',
      editField: 'Edit field',
      fieldType: 'Field type',
      fieldDescription: 'Field description',
      fieldCode: 'Field code',
      options: 'Options (below)',

      textinput: 'Textbox',
      textareainput: 'Long textbox',
      checkbox: 'Checkbox',
      dropdown: 'Dropdown list',
      statictext: 'Static text',
    },
  },
  settings: {
    companyInfo: 'Company details',
    payments: 'Payments',
    addField: 'Add fied',
    billsCategories: 'Bill categories',
    editField: 'Edit field',
    forms: {
      forms: 'Forms',
      addForm: 'Add form',
      updateForm: 'Edit form',
      active: 'Active',
      formName: 'Form name',
      actionType: 'Action type',
      configure: 'Configure',
    },
    billingScope: 'Billing period',
    saveBeforeLeavePage: 'Save changes before closing the tab?',
    enableInternalPayments: 'Enable payment gateway',
    periodicPaymentTemplates: 'Recurring payments templates',
    clientGroups: 'Payer groups',
    clientPropertyRemove: 'Information about all payers from this field will be erased!',
    defaultPaymentMethod: 'Default payment method',
    settlements: 'Settlements',
    administrators: 'Administrators',
    access: 'Access',
    roles: {
      add: 'Add access',
      edit: 'Edit acccess',
      types: {
        payeeAdmin: 'Payee adminstrator',
        payersView: 'Access to payers',
        shopOrdersView: 'Access to orders',
      },
    },
    adminPermissions: 'Admin permissions',
    paymentIntegrations: 'Payment integration',
    fullCompanyName: 'Full company name',
    shortCompanyName: 'Short company name',
    paymentGateway: 'Payment gateway',
    paymentGateways: 'Payment gateways',
    paymentCommission: 'Payment commission',
    commission: {
      oneForThousand: '1zł / 1000zł',
      oneForThousandInfo: '1zł for every 1000zł',
      companyPays: 'Payments covered by organisation',
    },
    gateway: {
      enabled: 'Gateway active',
      disabled: 'Gateway disabled',
      isDefault: 'Default',
      add: 'Add gateway',
      edit: 'Edit gateway',
      categoriesOverlap:
      `Selected categories are already assigned to another gateway!
      Deselect them before proceeding`,
    },
    NIP: 'NIP',
    PESEL: 'PESEL',
    vatNumber: ' VAT number (NIP)',
    emailAdress: 'Email address',
    country: 'Country',
    address: 'Address',
    phoneNumber: 'Phone number',
    bankAccountNumber: 'Bank account number',
    clientsProperties: 'Client details',
    includeBankAccountNumber: 'Add account number to all future payments',
    clientProp: {
      propertyName: 'Name',
      activationEmail: 'Email for account activation',
      groupping: 'Group type',
      code: 'Code',
    },
    group: {
      name: 'Group name',
      code: 'Group code',
      parent: 'Groupa nadrzędna',
    },
    transferPrefix: 'Transfer title templates',
    notifications: 'Notifications',
    remindLatePayments: 'Remind about overdue payments',
    categories: {
      name: 'Name',
      add: 'Add new billing category',
      edit: 'Edit billing category',
      default: 'Default',
      active: 'Active',
      errorDefault: 'Default channel cannot be deleted',
    },
    notify: {
      reminders: 'Notifications',
      smsNotifications: 'SMS notifications',
      uninvitedPayers: 'Uninvited payers',
      sendBillUpdates: 'Notify about new and cancelled billings',
      sendReminders: 'Remind about payments due',
      sendNewMessages: 'Notify about new messages',
      emailToEnable: 'Contact us at pomoc@pagaspot.com to add SMS notifications to your subscription',
      default: 'You will received additional reminder the day before the payment deadline',
      sendRemindersBySms: 'Send payment reminders via SMS',
      smsCount: 'Number of text messages left to send',
      byEmail: 'By email',
      never: 'Never',
      every3days: 'Every 3 days',
      everyWeek: 'Every week',
      everyTwoWeeks: 'Every fortnight',
      everyMonth: 'Every month',
      addSms: 'Add SMS',
      sendBillDueDateExpired: 'Remind me about a bill due after deadline',
    },
    clientField: {
      other: 'Other',
      email: 'Email',
      phone: 'Phone number',
      accountNumber: 'Account number',
    },
    clientCode: 'Show payer IDs',
    info: {
      defaultGateway: 'Gateway set as default will be used for every category which was not directly configured',
      internalPayments: `
          By turning on payment gateways you will allow your payers to settle the payment faster!<br> 
          Payments made this way will be added and settled automatically.<br>
          If you want to turn on a percentage gateway, contact us at: pomoc@pagaspot.com`,
      clientCode: 'If two or more payers have the same name and surname, add a payer ID which will enable to distinguish them.',
      requestBankAccountNumber: `No active account number. Please add it below and wait for approval.
          If it is urgent, write to us at pomoc@pagaspot.com`,
      notifyToChangeAccount: 'In order to change the account number, contact us at pomoc@pagaspot.com',
      sendRemindersToNotInvited: 'Even payers who were not invited to Pagaspot will receive an email with information about unpaid bills',
      sendNewMessagesToNotInvited: 'Even payers who were not invited to Pagaspot will receive an email with all the messages which you send to them.',
      sendBillUpdatesBySms: 'We will also send information about new and cancelled bills by SMS.',
      sendBillUpdatesToNotInvited: 'Even payers who were not invited to Pagaspot will receive information about new and cancelled bills.',
      sendRemindersBySms: 'We will also send information about unpaid bills by SMS',
      smsCount: `This number decreases as Pagaspot sends text messages in your name. 
        If your message contains Polish characters, one SMS has a maximum of 70 charactes. Otherwise it is 160 characters.`,
      remindLatePayments: 'How often would you like us to notify your clients about unpaid bills?',
      sendBillDueDateExpired: 'We will inform those who have not yet made the payment when its deadline approaches.',
      companyPaysCommission: `Additional charges made by the payer will be deducted from the amount due. 
        The amount settled on your account will be analogically smaller.`,
      sendBillingScopeSummary: `On the selected day, we will send to each payer a document containing all bills
        along with the current balance`,
      billingScopeDay: 'billing scope day',
    },
    currencies: {
      currency: 'Currency',
      currencies: 'Currencies',
      alertNoMoreCurr: 'These are all the currencies we use',
      alertDeleteCurr: 'Do you really want to delete this currency?',
      infoNbp: 'Exchange rates were imported from the Polish National Bank. You can set also your own.',
      currencyRateInfo: 'Exchange rate applied',
      currencyAmountInfo: 'We will settle',
      currencySelect: 'Choose currency',
      currencyInfo: 'Let us know if your currency is missing: support@pagaspot.com',
      currencyErrNoCurrency: 'You must set at least one currency',
      sendError: 'Try again',
      clickCurrencyInfo: 'Select currency you want to add',
    },
    bankAccounts: {
      alertDeleteAcc: 'Are you sure you want to delete this bank account?',
      addBankAccount: 'Add bank account',
      typeAccNumber: 'Account number',
      typeAccName: 'Account name',
      bankAccount: 'Bank account',
      noBankAccount: 'No bank account to show',
      accNumber: 'Account number',
      prefixAccount: 'Individual accounts',
      currency: 'Currency',
      waitingToVerify: 'Waiting for verification',
      visible: 'Visible for payer',
      verifyDone: 'Verified',
      rejected: 'Rejected',
      somePayersNotConfigured: 'Some payers are not assigned to this account number',
      accountNotValidatedInfo: `Until this account number is verified,
      it will not be displayed to payers and the payment gateway will be blocked.
      Message us at pomoc@pagaspot.com if the account number remains unverified.`,
      noValidatedAccount: 'No verified account number',
      noValidatedAccountInfo: `Payers will not be able to use this payment gateway.
      Add your account number in the settings and wait for verification or message us at pomoc@pagaspot.com`,
      specialCharactersInfo: `If you are using invidual accounts, use <strong>_</strong><br>
        instead of distinctive digits in the account number.`,
      noAccountWithPlaceholder: `You have not defined any account using dynamic fields.
        Go to "Receivables" in Settings and add a rule for account numbers using a"_"`,
    },
  },
  error: {
    error: 'Error',
    internalError: '<b>Could not download data. Try again later. </b><br>If you encounter this problem again, contact us at pomoc@pagaspot.com',
    fail: 'Unable to download data',
    wrongPassword: 'Wrond password!',
    noExcess: 'No excess to refund',
    settingsUnavailable: 'Unable to download settings details. Try again later.',
    enterName: 'Enter payer name and surname!',
    selectPayers: 'Select payers',
    enterPaymentName: 'Enter payment title',
    enterPaymentAmount: 'Add payment amount',
    invalidAmount: 'Invalid amount',
    importImpossible: 'Import failed',
    enterAtLeastThree: 'Enter at least 3 characters',
    enterAtLeastEight: 'Enter at least 8 characters',
    enterTenDigits: 'Enter 10 digits',
    mandatoryField: 'This field is mandatory',
    fillMandatoryFields: 'Fill all mandatory fields',
    emailInfo: 'Email address must contain @ and at least 5 characters',
    demoVersionUserLimit: 'DEMO account only allows you to add 10 users',
    demoVersionActivateAdmin: 'DEMO account only allows you to activate admins',
    type: {
      CodeUsed: 'Code has been already used',
      CodeExpired: 'Code has expired',
      EmailExists: 'This emails address already exists. Return to log in and use "Magic Link" if you forgot your password',
      UserNotVerified: 'Account was not verified. Use "Magic Link" to verified your email',
      Unknown: 'Unknown error',
      InvalidEmail: 'Invalid email address',
      InvalidPassword: 'Invalid password',
      NoAccess: 'Access denied',
      InvalidCode: 'Invalid code',
      BillsInChannelExists: 'There are bills assigned to this channel',
      BillsChannelIsDefault: 'You cannot delete default channel',
    },
  },
  time: {
    lessThanDay: 'Less than day',
    date: 'Date',
    month: 'Month',
    quarter: 'Quarter',
    hrs: 'hrs',
    year: 'Year',
    months: {
      zero: 'Months',
      one: 'Month',
      few: 'Months',
      many: 'Months',
    },
    day: 'Day',
    days: 'Days',
    week: 'Week',
    weeks: {
      zero: 'Weeks',
      one: 'Week',
      few: 'Weeks',
      many: 'Weeks',
    },
    desc: {
      day: `Payment will not be billed 
      | Payment will be billed daily  
      | Payment will be billed every {n} days`,
      week: {
        zero: 'Payment will not be billed ',
        one: 'Payment will be billed every {weekday}',
        few: 'Payment will be billed every {weekday}, every {n} weeks',
        many: 'Payment will be billed every {weekday}, every {n} weeks',
      },
      month: {
        zero: 'Payment will not be billed ',
        one: 'Payment will be billed every {day} of every month',
        few: 'Payment will be billed on {day} every {n} months',
        many: 'PPayment will be billed on {day}-every {n} months',
      },
    },
  },
  messages: {
    newMessage: 'New message',
    message: 'Message',
    send: 'Send',
    sendNow: 'Send now',
    skip: 'Skip',
    skipped: 'Skipped',
    noThreads: 'No threads to show',
    reply: 'Reply',
    replyAll: 'Reply all',
    replySelected: 'Reply selected',
    selectCompany: 'Select company',
    title: 'Message title',
    sendAsSms: 'Sens as SMS',
    characters: 'characters',
    markAsUnread: 'Marks as unread',
    numberOfSmsForThisMessage: 'Number of SMS to send for this message',
    remainingSmsMessages: 'Remaining SMS this month',
  },
  import: {
    file: 'File',
    upload: 'Import file by dropping it here or choose your file manually.',
    unableToImport: `<strong>Unable to import file <br> Check if the file type is correct (ex. if it was not modified)
     Try again or contact us by sendint the file to pomoc@pagaspot.com`,
    allSettled: 'All transfered have been settled! Use the field above to upload new file',
    noTransfers: 'No transfers found',
    expectedFormat: 'Expected file format',
    alreadySettled: 'This has already been settled!',
    resetFormat: 'Reset format',
    unsettled: 'Unsettled',
    ignored: 'Ignored',
    settled: 'Settled',
    matched: 'Matched',
    unmatched: 'Unmatched',
    setGroups: 'Select groups',
    thereAreSomeIssues: 'There are some issued with this file',
    noIssues: 'Everthing is alright! Proceed to save the data',
    noImport: 'Those columns were not imported',
    imported: 'Imported',
    errors: 'Errors',
    duplicates: 'Duplicates',
    moveHereToIgnore: 'Move columns here to ignore them',
    columnsInfo: 'Set columns by dragging  and arranging them in a way they correspond to the order in the source file',
    duplicateDectionPerson: 'Payer will be recognised from their name, surname and payer code (if they were imported)',
    duplicateDectionOrganization: 'Payer will be recognised from their company name and NIP (if they were imported)',
    loading: {
      1: 'We are analysing your file',
      2: 'Checking for duplicates',
      3: 'Comparing to other payments',
      4: 'Arranging payers',
      5: 'Preparing to display',
    },
  },
  notify: {
    pendingNotifications: 'Notification pending to be send',
    sentNotifications: 'Notificatins sent',
    willNotReceive: 'This notification will not be send to this payer due to current settings and activiation status',
    skipped: 'Notification has been skipped. No payer received the message',
    nooneWillReceiveIt: 'No payer will receive this notification',
    nooneReceivedIt: 'No payer will received this notification',
    nooneWillReceiveItHelp: `Make sure that contact details are complete and payers have been activated,
     or sending notifications to unregistered has been turned on`,
    delivered: 'Sent',
    push: 'Push notification',
    notDelivered: 'Not delivered',
    type: {
      BillCancelledNotification: 'Bill cancelled',
      BillCreatedNotification: 'New bill created',
      MessageSentNotification: 'New message',
      BillDueDateExpiredNotification: 'Payment due',
      BillsReminderNotification: 'Payments overdue',
      BillingScopeSummaryNotification: 'Billing scope summary',
    },
  },
  enrollements: {
    newEnrollements: 'New enrollements',
    removals: 'Removals',
    approvals: 'Approved enrollements',
    add: 'Add enrollment',
    date: 'Date',
    status: {
      status: 'status',
      waitingForPayment: 'Waiting for payment',
      paid: 'Paid',
    },
  },
  page: {
    visableColumns: 'Visible columns',
    hiddenColumns: 'Hidden columns',
    space: '[ - space - ]',
  },
};
