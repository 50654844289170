import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getBillingPlans',
    path: 'billing-plans',
  })
  .get({
    action: 'getBillingPlanPayers',
    path: ({ billingPlanId }) => `billing-plans/${billingPlanId}/payers`,
  })
  .post({
    action: 'addBillingPlan',
    path: '/billing-plans',
  })
  .put({
    action: 'updateBillingPlan',
    path: ({ billingPlanId }) => `billing-plans/${billingPlanId}`,
  })
  .delete({
    action: 'removeBillingPlan',
    path: ({ billingPlanId }) => `billing-plans/${billingPlanId}`,
  })
  .post({
    action: 'addBillingPlanPayers',
    path: ({ billingPlanId }) => `billing-plans/${billingPlanId}/payers`,
  })
  .put({
    action: 'updateBillingPlanPayers',
    path: ({ billingPlanId }) => `billing-plans/${billingPlanId}/payers`,
  })
  .getStore();

export default store;
