import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .post({
    action: 'addBankAccount',
    path: 'bills-setup/bank-accounts',
  })
  .put({
    action: 'updateBankAccount',
    path: 'bills-setup/bank-accounts',
  })
  .put({
    action: 'verifyBankAccount',
    path: ({ accountNumber }) => `bills-setup/bank-accounts/${accountNumber}/verify`,
  })
  .put({
    action: 'rejectBankAccount',
    path: ({ accountNumber }) => `bills-setup/bank-accounts/${accountNumber}/reject`,
  })
  .delete({
    action: 'deleteBankAccount',
    path: ({ accountNumber }) => `bills-setup/bank-accounts/${accountNumber}`,
  })
  .getStore();

export default store;
