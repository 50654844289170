import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {

  },
})
  .get({
    action: 'getBalanceReport',
    path: ({ from, to }) => `/report/balance/${from}/${to}`,
  })
  .get({
    action: 'exportBalanceReport',
    requestConfig: {
      responseType: 'blob',
    },
    path: ({ from, to }) => `/report/balance/${from}/${to}?export=true`,
  })
  .getStore();

export default store;
