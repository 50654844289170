import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {

  },
})
  .post({
    action: 'parsePayers',
    path: '/payers/import',
  })
  .post({
    action: 'importBillsValidate',
    path: 'bills/import/validate',
  })
  .post({
    action: 'importBills',
    path: 'bills/import',
  })
  .getStore();

export default store;
