import axios from '@/api/axios';
import colors from '@/utils/colors';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    payeeId: null,
    tenantId: null,
    payee: null,
    scrollPadding: 0,
    payeeProperties: {},
  },
})
  .get({
    action: 'getPayees',
    property: 'payees',
    path: 'payees',
  })
  .get({
    action: 'getPayee',
    property: 'payee',
    path: ({ payeeId }) => `payee/${payeeId}`,
  })
  .put({
    action: 'setPayeeBillsSetup',
    path: 'bills-setup',
  })
  .get({
    action: 'getPayeeProperties',
    path: 'payee/properties',
    onSuccess: (s, { data }) => {
      data.forEach((p) => {
        Vue.set(s.payeeProperties, p.key, p.value);
      });
    },
  })
  .put({
    action: 'setPayeeProperties',
    path: 'payee/properties',
    onSuccess: (s, { data }) => {
      Vue.set(s.payeeProperties, data.key, data.value);
    },
  })
  .put({
    action: 'setContactInfo',
    path: 'payees/contact-info',
    onSuccess: (s, { data }) => {
      s.payee.contactEmail = data.contactEmail;
      s.payee.contactPhone = data.contactPhone;
    },
  })
  .put({
    action: 'setLogo',
    path: 'payees/logo',
    onSuccess: (s, { data }) => {
      s.payee.logo = data.logo;
    },
  })
  .getStore();

store.mutations.setPayeeId = (s, id) => {
  s.payee = null;
  s.payeeId = id;
  axios.defaults.headers.common.payeeId = id;
};

store.mutations.triggerShopEnabled = (s, value) => {
  if (s.payee) {
    s.payee.shopEnabled = value;
  }
};

store.mutations.setScrollPadding = (s, value) => {
  s.scrollPadding = value;
};

store.mutations.setCategories = (s, categories) => {
  if (!s.payee) return;
  s.payee.categories = categories;
};

store.mutations.updateBillingPayee = (s, payee) => {
  s.payee.bankAccounts = payee.bankAccounts;
  s.payee.categories = payee.categories;
  s.payee.billingScopeType = payee.billingScopeType;
};

store.getters = {
  payeeId: (s) => s.payeeId,
  tenantId: (s) => s.tenantId,
  payee: (s) => s.payee,
  payeeProperty: (s) => (name) => s.payeeProperties[name],
  showCategories: (s) => (s.payee?.categories || []).length > 1,
  categoryColor: (s) => (categoryId) => {
    const colorCode = (s.payee?.categories || []).find((x) => x.id === categoryId)?.colorCode;
    return colorCode ? colors(colorCode) : '#333';
  },
  bankAccounts: (s) => s.payee?.bankAccounts || [],
  scrollPadding: (s) => s.scrollPadding,
  payeeDemo: () => false,
};

export default store;
