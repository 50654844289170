<template>
  <Tippy
    trigger="click"
    theme="light"
    arrow
    interactive
  >
    <template #trigger>
      <slot>
        <div
          class="px-2 remove-icon align-self-center"
          :class="{ archive }"
        >
          <i
            v-if="archive"
            class="fas fa-box-archive"
          />
          <i
            v-else
            class="fas fa-trash-alt"
          />
        </div>
      </slot>
    </template>

    <div
      v-if="removeWarning"
      class="mb-2 font-weight-bold"
    >
      {{ removeWarning }}
    </div>
    <div>
      <button
        class="btn btn-sm btn-block"
        :class="archive ? 'btn-secondary' : 'btn-danger'"
        :disabled="pending"
        @click="$emit('confirm')"
      >
        <span v-if="archive">
          <i class="fas fa-box-archive pr-2" />
          {{ $t('general.archive') }}
        </span>
        <span v-else>
          <i class="fas fa-trash-alt pr-2" />
          {{ $t('general.remove') }}
        </span>
      </button>
    </div>
  </Tippy>
</template>

<script>
export default {
  props: {
    removeWarning: String,
    archive: Boolean,
    pending: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  .remove-icon {
    color: #888;
    cursor: pointer;

    &:hover {
      color: $red;
    }
    &.archive:hover {
      color: $blue;
    }
  }
</style>
