import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    scopes: [],
  },
})
  .get({
    action: 'getBills',
    path: () => '/bills',
    onSuccess: (s, { data }) => {
      s.scopes = data.scopes;
    },
  })
  .get({
    action: 'getBill',
    path: ({ billId }) => `/bills/${billId}`,
  })
  .delete({
    action: 'removeBill',
    path: ({ billId }) => `/bills/${billId}`,
  })
  .get({
    action: 'exportBill',
    requestConfig: {
      responseType: 'blob',
    },
    path: ({ billId }) => `/bills/${billId}/export`,
  })
  .get({
    action: 'getOutstandingBills',
    path: '/bills/outstanding',
  })
  .post({
    action: 'addBill',
    path: '/bills',
  })
  .put({
    action: 'updateBillDetails',
    path: ({ billId }) => `/bills/${billId}`,
  })
  .get({
    action: 'getPayerBillingTimeline',
    path: ({ payerId }) => `/report/${payerId}/timeline`,
  })
  // .get({
  //   action: 'getPayerBillingDetails',
  //   path: ({ payerId }) => `/bills/${payerId}/timeline/details`,
  // })
  .post({
    action: 'addBillPayers',
    path: ({ billId }) => `/bill/${billId}/payers`,
  })
  .get({
    action: 'getBillPayerDetails',
    path: ({ billId, payerId }) => `/bills/${billId}/payer/${payerId}`,
  })
  .getStore();

export default store;
