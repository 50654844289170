import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    bills: [],
    scopes: [],
  },
})
  .get({
    action: 'getBillPayers',
    path: ({ billId }) => `/bill/${billId}/payers`,
  })
  .put({
    action: 'updateBillPayers',
    path: ({ billId }) => `/bill/${billId}/payers`,
  })
  .put({
    action: 'validateBillPayersUpdate',
    path: ({ billId }) => `/bill/${billId}/payers/validate/overpayment`,
  })
  .put({
    action: 'validateBillPayersBankAccounts',
    path: ({ billId }) => `/bill/${billId || '00000000-0000-0000-0000-000000000000'}/payers/validate/bank-account`,
  })
  .getStore();

export default store;
