import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    schedule: [],
  },
})
  .put({
    action: 'getSmsMessageCost',
    path: '/notify/sender/sms/cost',
  })
  .get({
    action: 'getSmsLimit',
    path: '/notify/sender/limits',
  })
  .get({
    action: 'getPayeeNotifyPreferences',
    path: '/notify/sender/preferences',
  })
  .put({
    action: 'setPayeeNotifyPreferences',
    path: '/notify/sender/preferences',
  })
  .get({
    action: 'getNotificationsPlan',
    path: '/notify/sender/plan',
  })
  .put({
    action: 'getNotification',
    path: '/notify/sender/notification',
  })
  .put({
    action: 'skipNotification',
    path: '/notify/sender/notification/skip',
  })
  .put({
    action: 'sendNotification',
    path: '/notify/sender/plan/send',
  })
  .put({
    action: 'generatePaymentLink',
    path: () => '/notify/receiver/payment-link',
  })
  .getStore();

export default store;
