import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    unread: 0,
  },
})
  .get({
    action: 'getPayeeThreads',
    path: '/messages/payee/threads',
  })
  .post({
    action: 'addPayeeThread',
    path: '/messages/payee/threads',
  })
  .get({
    action: 'getPayeeMessages',
    path: ({ threadId }) => `messages/payee/${threadId}`,
  })
  .post({
    action: 'sendPayeeMessage',
    path: '/messages/payee',
  })
  .put({
    action: 'updateThreadStatus',
    path: ({ threadId }) => `/messages/payee/threads/${threadId}/status`,
  })
  .get({
    action: 'getUnreadMessages',
    path: '/messages/payee/unread',
    onSuccess: (s, { data }) => {
      s.unread = data.count;
    },
  })
  .put({
    action: 'markThreadAsRead',
    path: ({ threadId }) => `/messages/payee/threads/${threadId}/read`,
  })
  .put({
    action: 'markThreadAsUnread',
    path: ({ threadId }) => `/messages/payee/threads/${threadId}/unread`,
  })
  .delete({
    action: 'removeMessage',
    path: '/messages/payee',
  })
  .getStore();

store.getters = {
  unreadMessages: (s) => s.unread,
};

export default store;
