<template>
  <div
    class="colorful-custom-checkbox d-flex"
    :class="{ 'clickable': !readOnly }"
    @click="emit ? $emit('update:value', !value) : null"
  >
    <div class="color-box-container px-2">
      <div
        v-if="!pending"
        class="color-box square-box"
        :class="{ selected: value }"
        :style="{ 'background-color': value ? color : unColor }"
      >
        <i
          v-if="value"
          class="fas fa-check"
        />
      </div>
      <div v-else>
        <Loader size="15px" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    color: String,
    pending: Boolean,
    readOnly: Boolean,
    unColor: {
      type: String,
      default: '#dedede',
    },
    emit: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>

  .square-box {
    border-radius: 4px;
    height: 16px;
    width: 16px;
    color: white;
    text-align: center;
    font-size: 10px;
    padding-left: 1px;
    line-height: 16px;

    &.selected {
      background-color: $blue;
    }
  }

  .clickable {
    cursor: pointer;
  }
</style>
