import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .get({
    action: 'getBankStatements',
    path: () => '/bank-statements',
  })
  .get({
    action: 'getTransfers',
    path: () => '/bank-statements/transfers',
  })
  .post({
    action: 'importBankStatement',
    path: () => '/bank-statements',
  })
  .put({
    action: 'settleTransfer',
    path: ({ transferId }) => `/bank-statements/transfer/${transferId}/settle`,
  })
  .put({
    action: 'skipTransfer',
    path: ({ transferId }) => `/bank-statements/transfer/${transferId}/skip`,
  })
  .get({
    action: 'suggestTransferSettlement',
    path: ({ transferId }) => `/bank-statements/transfer/${transferId}/suggest`,
  })
  .getStore();

export default store;
