import axios from '@/api/axios';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    groups: [],
  },
})
  .get({
    action: 'getPayersGroups',
    path: '/payers-groups',
    onSuccess: (s, { data }) => {
      s.groups = data;
    },
  })
  .post({
    action: 'addPayersGroup',
    path: '/payers-groups',
    onSuccess: (s, { data }) => {
      s.groups.push(data);
    },
  })
  .delete({
    action: 'removePayersGroup',
    path: '/payers-groups',
    onSuccess: (s, _, a, { params }) => {
      s.groups = s.groups.filter((x) => x.code !== params.query.code);
    },
  })
  .put({
    action: 'updatePayersGroup',
    path: '/payers-groups',
    onSuccess: (s, { data }) => {
      const group = s.groups.find((x) => x.code === data.code);
      Vue.set(group, 'name', data.name);
      Vue.set(group, 'parentCode', data.parentCode);
      Vue.set(group, 'rule', data.rule);
    },
  })
  .put({
    action: 'testPayersGroupRule',
    path: '/payers-groups/test-rule',
  })
  .getStore();

store.mutations.clearGroups = (s) => {
  s.groups = [];
};
store.mutations.groupsNeedRefresh = (s) => {
  s.needsRefresh = true;
};

const getGroupPath = (groups, g) => {
  if (!g.parentCode || g.parentCode === '#all') return g.name;
  const parent = groups.find((x) => x.code === g.parentCode);
  return `${getGroupPath(groups, parent)}/${g.name}`;
};

store.getters = {
  groups: (s) => s.groups
    .map((g) => ({
      ...g,
      path: getGroupPath(s.groups, g),
    }))
    .sort((a, b) => {
      if (a.code === '#all') return -1;
      if (b.code === '#all') return 1;
      return a.path.localeCompare(b.path);
    }),
  userGroups: (_, g) => g.groups
    .filter((x) => !x.code.startsWith('#')),
};

export default store;
