import userBills from './modules/bills';
import pay from './modules/pay';
import settings from './modules/settings';
import userInvites from './modules/user-invites';
import userMessages from './modules/user-messages';
import userNotify from './modules/user-notify';
import userShop from './modules/user-shop';

export default {
  userShop,
  userBills,
  userMessages,
  settings,
  userNotify,
  userInvites,
  pay,
};
