import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
    properties: [],
  },
})
  .get({
    action: 'getGateways',
    path: '/pay/gateways',
  })
  .put({
    action: 'updatePaymentGateway',
    path: ({ gatewayId }) => `/pay/gateways/${gatewayId}`,
  })
  .post({
    action: 'addPaymentGateway',
    path: '/pay/gateways',
  })
  .delete({
    action: 'removePaymentGateway',
    path: ({ gatewayId }) => `/pay/gateways/${gatewayId}`,
  })
  .getStore();

export default store;
