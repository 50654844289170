import Vapi from 'vuex-rest-api';
import moment from 'moment';
import lang from '@/lang';
import axios from '@/api/axios';

const store = new Vapi({
  axios,
  state: {
  },
})
  .put({
    action: 'closeBillingScope',
    path: ({ billingScopeId }) => `/billing-scopes/${billingScopeId}/close`,
  })
  .put({
    action: 'reopenBillingScope',
    path: ({ billingScopeId }) => `/billing-scopes/${billingScopeId}/open`,
  })
  .get({
    action: 'getBillingScope',
    path: ({ date }) => `/billing-scopes/${date}`,
  })
  .get({
    action: 'getBillingScopes',
    path: ({ from, to }) => `/billing-scopes/${from}/${to}`,
  })
  .getStore();

store.getters = {
  billingScopeName: () => ({ type, from, to }) => {
    if (type === 'Month') {
      return moment(from, 'YYYY-MM-DD').format('MMMM YYYY');
    }
    if (type === 'Quarter') {
      return moment(from, 'YYYY-MM-DD').format('Qo YYYY');
    }
    if (type === 'Year') {
      return moment(from, 'YYYY-MM-DD').format('YYYY');
    }
    if (type === 'Week') {
      return `${lang.t('time.week')}
       ${moment(from, 'YYYY-MM-DD').format('DD/MM')} - ${moment(to, 'YYYY-MM-DD').format('DD/MM')}`;
    }
    return '?';
  },
};

export default store;
